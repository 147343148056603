
import { Component, Prop, Vue } from 'vue-property-decorator';
import ArrowRightOutlined from '@/icons/ArrowRightOutlined.vue';
import { Row, Col } from 'ant-design-vue';
import { FileData } from '@/views/TechnicalDocumentation.vue';
// @ts-ignore
import downloadFile from 'downloadjs';
import Attachment from '@/models/Attachment';
import { showErrorNotifications } from '@/helpers/NotificationHelper';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { PopupEvents } from '@/enums/global/PopupEvents';
import { AxiosError } from 'axios';

@Component({
    name: 'DocumentList',
    components: { ArrowRightOutlined, Col, Row },
})
export default class DocumentList extends Vue {
    @Prop({ default: () => [] }) private menuData!: FileData[];
    @Prop({ required: true }) private isLoading!: boolean;

    private async downloadGivenFileOrImage(file: FileData) {
        this.isLoading = true;
        let blob;
        try {
            blob = await Attachment.getBlob(file.link as string);
        } catch (e) {
            showErrorNotifications(e as AxiosError<any>);
            return Promise.reject(e);
        }

        downloadFile(blob.data, file.name + '.' + file.fileType);
        this.isLoading = false;

        return Promise.resolve();
    }

    private getExtensionIcon(extension: string) {
        if (extension === 'jpg' || extension === 'jpeg') {
            return 'picture';
        } else if (extension === 'pdf') {
            return 'file-pdf';
        } else if (extension === 'xls' || extension === 'xlsx') {
            return 'file-excel';
        } else if (extension === 'directory') {
            return 'folder';
        } else {
            return 'file';
        }
    }

    private handleSelectedItem(item: any) {
        if (item.fileType === 'directory') {
            this.$emit('selected-directory', item);
        }
        // tslint:disable-next-line:max-line-length
        if (
            item.fileType === 'pdf' ||
            item.fileType === 'jpg' ||
            item.fileType === 'jpeg' ||
            item.fileType === 'png' ||
            item.fileType === 'mp4'
        ) {
            this.previewFile(item);
        }
    }

    private async previewFile(item: any) {
        let blob;
        try {
            blob = await Attachment.getBlob(item.link as string);
        } catch (e) {
            return Promise.reject(e);
        }

        EventBus.$emit(EventBusEvents.onOpenPreviewFilePopup, {
            popupEvent: PopupEvents.openPreviewFilePopup,
            data: {
                data: { ...item, blob: blob?.data },
            },
        });
    }
}
