
import { Vue, Component, Watch } from 'vue-property-decorator';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import ClientsTableWrapper from '@/components/views/ClientView/ClientsTableWrapper.vue';
import TableSearchBar from '@/components/global/TableSearchBar.vue';
import ClientTopBar from '../components/views/ClientView/ClientTopBar.vue';
import SettingsTopBar from '@/components/views/settings/SettingsTopBar.vue';
import { RouteNames } from '@/enums/routes/RouteNames';
import DocumentList from '@/components/global/technical-documentation/DocumentList.vue';
import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { Col } from 'ant-design-vue';
import i18n from '@/i18n';
import { CheckUnsavedDataMixin } from '@/mixins/CheckUnsavedData';
import LocalePicker from '@/components/global/LocalePicker.vue';
import { SearchEntityFilter } from '@/interfaces/SearchEntityFilter';
import { ElementFilterType } from '@/enums/global/ElementFilterType';

export interface FileData {
    id: string;
    icon: any;
    name: string;
    canSee?: any;
    link: string;
    fileType: string;
}

@Component({
    name: 'TechnicalDocumentation',
    components: {
        DocumentList,
        ClientsTableWrapper,
        TableSearchBar,
        ClientTopBar,
        LocalePicker,
        SettingsTopBar,
        Col,
    },
    mixins: [CheckUnsavedDataMixin],
})
export default class TechnicalDocumentation extends Vue {
    private search = {
        isActive: true,
    };
    private backRoute: RouteNames = RouteNames.settings;
    private techicalDirectories = [];
    private activeFilters: SearchEntityFilter[] = [];
    private elementFilterType = ElementFilterType;
    private technicalFiles = [];
    private menuData: FileData[] = [];
    private hasUnsavedData = false;
    private locale = 'sl';
    private technicalDirectories: any[] = [];
    private langDirectories: any[] = [];
    private selectedDirectory: null | any = null;
    private searchQuery = '';
    private visible = false;
    private areFiltersVisible = false;
    private searchedFiles = [];
    private path: any[] = [];
    private isLoading = true;
    private allChecked = false;
    private selectedLocale: string | null = this.currentLocale;
    private selectedFilter = {};
    private availableFilters = [
        {
            defaultValue: true,
            filter: 'images',
            label: 'Images',
            type: 'Switch',
            value: true,
            fileTypes: 'jpg,jpeg,png',
        },
        {
            defaultValue: true,
            filter: 'documents',
            label: 'Documents',
            type: 'Switch',
            value: true,
            fileTypes: 'pdf,xls,xsls,odf,doc,pptx',
        },
        {
            defaultValue: true,
            filter: 'video',
            label: 'Video',
            type: 'Switch',
            value: true,
            fileTypes: 'mp4,mov',
        },
    ];

    private localeDirectoriesMap = {
        sl: 'SLO',
        de: 'AUT',
        it: 'ITA',
        hu: 'HU',
        hr: 'HR',
        en: 'ANG',
    };

    @Watch('selectedLocale', {
        immediate: true,
    })
    private async handleSelectedLocaleChange(locale: any) {
        await this.setTechnicalByLanguage(locale);
    }

    @Watch('currentLocale', {
        immediate: true,
    })
    private async setDefaultLocale(locale: string) {
        this.selectedLocale = locale;
    }

    private async setTechnicalByLanguage(locale: string | null) {
        // @ts-ignore
        const dirName = this.localeDirectoriesMap[locale];

        const dir = this.langDirectories.find((item) => item.name === dirName);

        if (dir) {
            // @ts-ignore
            this.technicalDirectories = await this.getTechnicalDirectoriesByParent(dir.id);
        } else {
            this.technicalDirectories = [];
        }
    }

    private async handeSelectedFilters() {
        // tslint:disable-next-line:max-line-length
        const fileTypes = this.availableFilters
            .filter((item) => item.value && item.filter !== 'other')
            .map((item) => item.fileTypes)
            .join(',');
        // tslint:disable-next-line:max-line-length
        const notFileTypes = this.availableFilters
            .filter((item) => item.value && item.filter === 'other')
            .map((item) => item.fileTypes)
            .join(',');

        try {
            // tslint:disable-next-line:max-line-length
            const res = await ApiController.get(
                ApiRoutes.technicalFiles.searchBy(this.selectedDirectory?.id, this.searchQuery, fileTypes)
            );

            this.menuData = res.data.map((item: any) => {
                return {
                    id: item.id,
                    icon: item.fileType,
                    name: item.name,
                    canSee: true,
                    link: item.link,
                    fileType: item.fileType,
                };
            });
        } catch (e) {
            return Promise.reject(e);
        }
    }

    private async created() {
        EventBus.$on(EventBusEvents.runEntitySearch, this.onSearch);

        this.isLoading = true;
        await this.getRootDirectories();
        this.isLoading = false;
    }

    private get currentLocale() {
        if (this.$i18n == null) {
            return 'sl';
        }

        return this.$i18n.locale;
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.runEntitySearch, this.onSearch);
    }

    private toggleAllChecked() {
        if (this.allChecked) {
            this.availableFilters
                ?.filter((f) => f.type === this.elementFilterType.Switch)
                ?.forEach((f) => {
                    f.value = true;
                });
        } else {
            this.availableFilters
                ?.filter((f) => f.type === this.elementFilterType.Switch)
                ?.forEach((f) => {
                    f.value = false;
                });
        }
    }
    @Watch('availableFilters', { immediate: true, deep: true })
    private shouldToggleAll() {
        if (
            this.availableFilters?.filter((f) => f.type === this.elementFilterType.Switch && f.value === false)
                ?.length === 0
        ) {
            this.allChecked = true;
        } else {
            this.allChecked = false;
        }
    }

    private async handleDocumentList(item: any) {
        if (item === null) {
            return;
        }

        if (this.selectedDirectory) {
            this.path.push(this.selectedDirectory);
        }

        await this.handleSelectedDirectory(item);
    }

    private async handleBack() {
        const directory = this.path.pop();

        await this.handleSelectedDirectory(directory);
    }

    private async resetDirectoryList(directory: any) {
        this.path = [];

        await this.handleSelectedDirectory(directory);
    }

    private onClearFilters() {
        this.activeFilters.forEach((f) => this.removeFilter(f));
    }

    private removeFilter(filter: SearchEntityFilter) {
        // const filterIndex = this.availableFilters.findIndex((f) => f.label === filter.label);
        // if(filterIndex >= 0){
        //   this.availableFilters[filterIndex].value = filter.defaultValue;
        //   if (this.availableFilters[filterIndex].filter === 'assignedUser.id') {
        //     this.availableFilters[filterIndex].value = '';
        //   }
        // }
        EventBus.$emit(EventBusEvents.runEntitySearchWithParameters, {
            isSearchActive: true,
            availableFilters: this.availableFilters,
            page: 1,
        });
    }

    private async handleSelectedDirectory(directory: any) {
        this.isLoading = true;
        this.selectedDirectory = directory;

        if (this.selectedDirectory !== null) {
            // @ts-ignore
            const directories = await this.getTechnicalDirectoriesByParent(this.selectedDirectory.id);
            // @ts-ignore
            const files = await this.getTechnicalDocumentsByFilter(this.selectedDirectory.id);

            const merge = directories.concat(files);

            this.menuData = merge.map((item: any) => {
                return {
                    id: item.id,
                    icon: 'folder',
                    name: item.name,
                    canSee: true,
                    link: item.link,
                    fileType: item.fileType || 'directory',
                };
            });

            this.isLoading = false;
        } else {
            return;
        }
    }

    private async onSearch(id: string | null, query: string) {
        this.isLoading = true;
        if (query.length <= 3) {
            EventBus.$on(EventBusEvents.runEntitySearch, this.onSearch);

            this.isLoading = true;
            await this.getRootDirectories();
            this.isLoading = false;
        }
        if (id) {
            try {
                const filesRes = await ApiController.get(ApiRoutes.technicalFiles.search(query, id));

                const directoriesRes = await ApiController.get(ApiRoutes.technicalFiles.searchByDirectory(query, id));

                const files = filesRes.data.map((item: any) => {
                    return {
                        id: item.id,
                        icon: item.fileType,
                        name: item.name,
                        canSee: true,
                        link: item.link,
                        fileType: item.fileType,
                    };
                });

                const directories = directoriesRes.data.map((item: any) => {
                    return {
                        id: item.id,
                        icon: 'folder',
                        name: item.name,
                        canSee: true,
                        link: null,
                        fileType: 'directory',
                    };
                });

                this.menuData = directories.concat(files);
            } catch (e) {
                return Promise.reject(e);
            }
            this.isLoading = false;
        }
    }

    private async getRootDirectories() {
        this.isLoading = true;
        try {
            const response = await ApiController.get(ApiRoutes.technicalDirectories.root);

            const root = response.data;

            if (root.length === 1) {
                // @ts-ignore
                this.langDirectories = await this.getTechnicalDirectoriesByParent(root[0].id);
                await this.setTechnicalByLanguage(this.selectedLocale);
            }
        } catch (e) {
            return Promise.reject(e);
        }
        this.isLoading = false;
    }

    private async getTechnicalDocumentsByFilter(id: string): Promise<any[]> {
        try {
            const response = await ApiController.get(ApiRoutes.technicalDirectoriesFilter.getById(id));

            return response.data;
        } catch (e) {
            return Promise.reject(e);
        }
    }

    private async getTechnicalDirectoriesByParent(directoryId: string): Promise<any[]> {
        try {
            const response = await ApiController.get(ApiRoutes.technicalDirectories.get(directoryId));
            return response.data;
        } catch (e) {
            if ((e as any).response) {
                this.$notification.error({
                    message: i18n.t('Došlo je do greške') as string,
                    description: (e as Error).message,
                });
            }

            return Promise.reject();
        }
    }
}
