<template>


<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.5781 5.62187L5.675 0.498437C5.62969 0.459375 5.57188 0.4375 5.51094 0.4375H4.12813C4.0125 0.4375 3.95937 0.58125 4.04688 0.65625L9.51875 5.40625H0.375C0.30625 5.40625 0.25 5.4625 0.25 5.53125V6.46875C0.25 6.5375 0.30625 6.59375 0.375 6.59375H9.51719L4.04531 11.3438C3.95781 11.4203 4.01094 11.5625 4.12656 11.5625H5.55625C5.58594 11.5625 5.61562 11.5516 5.6375 11.5312L11.5781 6.37813C11.6322 6.33109 11.6756 6.27299 11.7053 6.20776C11.735 6.14253 11.7504 6.07168 11.7504 6C11.7504 5.92832 11.735 5.85747 11.7053 5.79224C11.6756 5.72701 11.6322 5.66891 11.5781 5.62187Z" fill="#262626"/>
</svg>


</template>

<script>
import { Component, Vue } from 'vue-property-decorator';

@Component({
    name: 'ArrowRightOutlined',
})
export default class ArrowRightOutlined extends Vue {
}
</script>

<style lang="scss" scoped>

</style>
